import React from "react";
import { styled } from "goober";

import HubLayout from "@/app/HubLayout.jsx";
import GeneralSettings from "@/hub-settings/GeneralSettings.jsx";
import settingsRefs from "@/hub-settings/refs.mjs";
import { useGameSymbol } from "@/util/game-route.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const StyledHubLayout = styled(HubLayout)`
  margin: auto;
`;

const Settings = () => {
  const gameSymbol = useGameSymbol();
  const { GameSettings } = useSnapshot(settingsRefs);

  const Component = gameSymbol ? GameSettings : GeneralSettings;

  return (
    <StyledHubLayout>
      <Component />
    </StyledHubLayout>
  );
};

Settings.hubRoute = () => {
  return {
    title: ["common:navigation.settings", "Settings"],
  };
};

export function meta() {
  return {
    title: ["common:navigation.settings", "Settings"],
    description: [],
  };
}

export default Settings;
