import React, { memo, useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { DELETE_USER, EDIT_INFO } from "@/hub-settings/constants.mjs";
import { userInfoRefs } from "@/hub-settings/UserInfo.refs.jsx";
import {
  ImageContainer,
  InfoContainer,
} from "@/hub-settings/UserInfo.style.jsx";
import AccountIcon from "@/inline-assets/settings-account.svg";
import DeleteIcon from "@/inline-assets/settings-delete.svg";
import EventedRender from "@/shared/EventedRender";
import { timestampToUTC } from "@/util/helpers.mjs";
import { getRegions } from "@/util/regions.mjs";
import useDropdownRef from "@/util/use-dropdown-ref.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const UserInfo = ({
  user,
  RevealHideBtn,
  RevealHideInfo,
  refs,
  handleSelectedModal,
  blurEnabled,
  setBlurEnabled,
  selectedLanguage,
}) => {
  const { name, birthday, location, insertedAt, gender } = user;
  const [showMenu, setShowMenu] = useState(false);
  const { ImageContainer: ImageContainerElement = ImageContainer, UserTag } =
    useSnapshot(userInfoRefs);
  const menuRef = useRef(null);

  const { t } = useTranslation();

  const delete_account = t("common:account.deleteAccount", "Delete Account");
  const ellipsis = "...";

  // TODO: i18n - locationName is stuck in English and all uppercase
  const locationName = useMemo(() => {
    if (location?.length === 2) {
      const regions = getRegions(selectedLanguage || "");
      return (
        regions.filter((region) => region.value === location)[0]?.label ||
        location
      );
    }
    return location;
  }, [location, selectedLanguage]);

  const metaData = [
    birthday && {
      label: t("common:settings.birthdate", "Date of Birth"),
      value: t("common:date", "{{date, datetime}}", {
        date: timestampToUTC(birthday),
        formatParams: {
          date: {
            month: "long",
            day: "numeric",
            year: "numeric",
          },
        },
      }),
      blur: true,
    },
    {
      label: t("common:settings.location", "Location"),
      value: locationName,
      blur: true,
    },
    {
      label: t("common:settings.gender", "Gender"),
      value: gender,
      blur: true,
    },
  ].filter(Boolean);

  const closeMenu = useCallback(() => {
    setShowMenu(false);
  }, []);

  const dropdownRef = useDropdownRef({
    onClickOutside: closeMenu,
    onWindowResize: closeMenu,
    onAssignNode: () => {},
  });

  return (
    <InfoContainer>
      <div className="info">
        <div className="top">
          <ImageContainerElement>
            <AccountIcon />
          </ImageContainerElement>
          <div className="header">
            <div className="flex name-section">
              <div className="flex name-wrapper">
                <div className="type-h6">{name}</div>
                <UserTag />
              </div>
              <div
                ref={menuRef}
                className="ellipsis"
                onClick={() => setShowMenu(true)}
              >
                {ellipsis}
                {showMenu && (
                  <div
                    ref={dropdownRef}
                    className="menu"
                    onClick={() => handleSelectedModal(DELETE_USER)}
                  >
                    <div className="type-form--button option">
                      <div className="icon">
                        <DeleteIcon />
                      </div>
                      <div>{delete_account}</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="type-caption label">
              {t(
                "common:account.accountCreated",
                "Account created {{date, datetime}}",
                {
                  date: new Date(insertedAt),
                  formatParams: {
                    date: {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    },
                  },
                },
              )}
            </div>
          </div>
        </div>
        <div className="lower">
          <div className="meta-data">
            {metaData.map((item) => {
              const blur = item.blur && blurEnabled ? "blur" : "";
              return (
                <RevealHideInfo
                  key={item.value}
                  label={item.label}
                  value={item.value}
                  blur={blur}
                />
              );
            })}
          </div>
          <div className="buttons">
            <RevealHideBtn
              blurEnabled={blurEnabled}
              blurFn={() => setBlurEnabled((blurEnabled) => !blurEnabled)}
            />
            <EventedRender>
              <refs.Edit editClick={handleSelectedModal} modal={EDIT_INFO} />
            </EventedRender>
          </div>
        </div>
      </div>
    </InfoContainer>
  );
};

export default memo(UserInfo);
