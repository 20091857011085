import { styled } from "goober";

export const Sessions = styled("div")`
  --grid-space: 24% 24% 24% 28%;
  display: flex;
  flex-direction: column;
  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: var(--sp-10);
  }
  .grid {
    display: flex;
    flex-direction: column;
  }
  .headers {
    display: grid;
    grid-template-columns: 25% 40% 15% 20%;
    justify-content: center;
    align-items: center;
    padding: var(--sp-4) 0 var(--sp-4) var(--sp-6);
    color: var(--shade2);
  }
  .session-container {
    > :nth-child(odd) {
      background-color: var(--shade8);
      border-radius: var(--br-sm);
    }
    grid-template-columns: var(--grid-space);
    .session-row {
      display: grid;
      align-items: center;
      grid-template-columns: 25% 40% 15% 20%;
      padding: var(--sp-3) 0 var(--sp-3) var(--sp-6);
      color: var(--shade1);
      > :nth-child(2) {
        user-select: none;
        &.blur {
          text-shadow: 0 0 var(--sp-4) var(--shade1);
          color: transparent;
          background-color: var(--shade1);
          opacity: 0.25;
          width: min-content;
        }
      }
    }
    .sign-out {
      transition: var(--transition);
      color: var(--primary);
      &:hover {
        cursor: pointer;
        color: var(--primary-50);
      }
    }
    .device {
      display: flex;
      flex-direction: row;
      gap: var(--sp-1);
    }
  }
  .session-item {
    display: flex;
    justify-content: center;
  }
  .buttons {
    display: flex;
    flex-direction: row;
    gap: var(--sp-2);
  }
`;
