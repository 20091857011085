import { forwardRef } from "react";
import { css, styled } from "goober";

import { OBSERVE_CLASS } from "@/util/exit-transitions.mjs";

export const HubContainer = styled("div", forwardRef)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: var(--sp-container);

  padding-block-end: var(--page-end);

  &:not(.${OBSERVE_CLASS}) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    min-height: 100%;
  }
`;

export const cssHubTransition = () => css`
  --direction: 1;
  --td: calc(var(--transition-distance, --sp-8) * var(--direction));

  transition: var(--transition);
  transition-duration: calc(var(--transition-duration) * 1.5);
  transition-property: opacity, transform;

  &.from {
    opacity: 0;
    transform: translateX(var(--td));
  }
  &.leave {
    --direction: -1;
    max-height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
`;

export const HubContent = styled("div", forwardRef)`
  position: relative;
  margin-block-start: var(--sp-6);
`;
