import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "clutch/src/Button/Button.jsx";
import { Select } from "clutch/src/Select/Select.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { IS_APP, IS_APRIL_FOOLS } from "@/__main__/constants.mjs";
import mainRefs from "@/__main__/refs.mjs";
import { showSnackbar, writeSettings } from "@/app/actions.mjs";
import { LOCALES, THEME_AUBERGINE, THEME_GHERKIN } from "@/app/constants.mjs";
import {
  // checkForUpdates,
  resetSessionData,
  restartApp,
  setHardwareAcceleration,
  setMinimizeBlitzToTray,
  setOpenAtLogin,
} from "@/hub-settings/actions.mjs";
import {
  IconWrapper,
  PageContainer,
  Panel,
  PanelWell,
  SettingsContent,
  SettingsDescription,
  SettingsLabel,
  SettingsList,
  SettingsListItem,
} from "@/hub-settings/Common.style.jsx";
import {
  DELETE_USER,
  EDIT_EMAIL,
  EDIT_INFO,
  SNACKBAR_ID,
} from "@/hub-settings/constants.mjs";
import {
  BlitzVersion,
  ButtonGroup,
  LanguageSelect,
  MetaData,
  MetaDataWrapper,
  SecondaryButton,
} from "@/hub-settings/GeneralSettings.style.jsx";
import LoginSessions from "@/hub-settings/LoginSessions.jsx";
import settingsRefs from "@/hub-settings/refs.mjs";
import { ToggleSwitch } from "@/hub-settings/ToggleSwitch.jsx";
import UserInfo from "@/hub-settings/UserInfo.jsx";
import LogoBlitz from "@/inline-assets/blitz-logo.svg";
import HelpIcon from "@/inline-assets/help.svg";
import ReplayIcon from "@/inline-assets/replay.svg";
import CheckMark from "@/inline-assets/settings-check-mark.svg";
import EventedRender from "@/shared/EventedRender.jsx";
import FullscreenOverlay from "@/shared/FullscreenOverlay.jsx";
import globals from "@/util/global-whitelist.mjs";
import themes from "@/util/themes.mjs";
import { useClientLayoutEffect } from "@/util/use-client-layout-effect.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function GeneralSettings() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [blurEnabled, setBlurEnabled] = useState(true);

  const {
    settings: {
      selectedLanguage,
      theme,
      disableAnimations,
      survey = { isSurveyDisabled: false },
    },
    user,
    volatile,
  } = useSnapshot(readState);

  const languageValue = selectedLanguage ?? language;

  const [selectedModal, setSelectedModal] = useState(null);
  const [snackbarText, setSnackbarText] = useState(null);

  const { settingsListItems, UserSettings, EditEmail, DeleteUser } =
    useSnapshot(settingsRefs);

  const handleSelectedModal = useCallback((modal) => {
    setSelectedModal(modal);
  }, []);

  const themeOptions = useMemo(() => {
    return Object.values(themes)
      .filter(({ key }) => {
        // april fools special
        if (
          key === THEME_AUBERGINE &&
          !IS_APRIL_FOOLS &&
          theme !== THEME_AUBERGINE
        ) {
          return false;
        }
        // logged-in only
        if (key === THEME_GHERKIN && !user && theme !== THEME_GHERKIN) {
          return false;
        }
        return true;
      })
      .map((th) => ({
        text: [th.nameKey, th.nameFallback],
        value: th.key,
      }));
  }, [theme, user]);

  // This one won't be translated because I'll just update this at some points.
  const specialThemes = `🍆 - April Fools<br>🥒 - Logged-in only`;

  const applicationSettings = [
    {
      name: "openAtLogin",
      label: t("common:settings.startup", "Run Blitz on startup"),
      input: (
        <ToggleSwitch
          value={volatile?.loginItemSettings?.openAtLogin ?? true}
          onChange={({ value }) => setOpenAtLogin(value)}
        />
      ),
      hidden: !IS_APP,
    },
    {
      name: "hardwareAcceleration",
      label: t(
        "common:settings.hardwareAccelerationTitle",
        "Enable Hardware Acceleration",
      ),
      description: t(
        "common:settings.hardwareAccelerationSubTitle",
        "Hardware Acceleration uses your GPU to make Blitz run smoother. Turn this off if you are experiencing issues while playing. If you change this option, Blitz will immediately restart.",
      ),
      input: (
        <ToggleSwitch
          value={volatile?.hardwareAcceleration?.enabled ?? false}
          onChange={({ value }) => setHardwareAcceleration(value)}
        />
      ),
      hidden: !IS_APP,
    },
    {
      name: "theme",
      label: (
        <>
          {t("common:settings.theme", "Theme")}
          <IconWrapper data-tip={specialThemes}>
            <HelpIcon />
          </IconWrapper>
        </>
      ),
      input: (
        <Select
          selected={theme}
          options={themeOptions}
          onChange={(value) => writeSettings(["theme"], value)}
        />
      ),
    },
    {
      name: "enableAnimations",
      label: t("common:settings.enableAnimations", "Enable Animations"),
      input: (
        <ToggleSwitch
          value={
            volatile?.hardwareAcceleration?.enabled === false
              ? false
              : !disableAnimations
          }
          onChange={({ value }) => writeSettings(["disableAnimations"], !value)}
          disabled={volatile?.hardwareAcceleration?.enabled === false}
        />
      ),
    },
    {
      name: "minimize",
      label: t("common:settings.minimizeTitle", "Minimize Blitz to tray"),
      description: t(
        "common:settings.minimizeSubTitle",
        "Pressing the close button minimizes to the tray rather than closing the app entirely",
      ),
      input: (
        <ToggleSwitch
          value={volatile?.minimizeBlitzToTray?.enabled ?? false}
          onChange={({ value }) => setMinimizeBlitzToTray(value)}
        />
      ),
      hidden: !IS_APP,
    },
    {
      name: "disableSurvey",
      label: t("common:settings.enableSurveys", "Enable Surveys"),
      input: (
        <ToggleSwitch
          value={!survey.isSurveyDisabled}
          onChange={({ value }) => {
            writeSettings(["survey", "isSurveyDisabled"], !value);
          }}
        />
      ),
    },
  ];

  const onChangeSelectedLanguage = useCallback(
    (event) => writeSettings(["selectedLanguage"], event.target.value),
    [],
  );

  const RevealHideInfo = ({ label, value, blur }) => (
    <MetaData>
      <div className="type-caption label">{label}</div>
      <div className={`type-body2 relative ${blur}`}>
        {value}
        <div className="hidden-placeholder">{"•".repeat(value?.length)}</div>
      </div>
    </MetaData>
  );
  const RevealHideBtn = ({ blurEnabled = true, blurFn }) => (
    <SecondaryButton className="form-button" onClick={() => blurFn()}>
      {blurEnabled ? t("common:reveal", "Reveal") : t("common:hide", "Hide")}
    </SecondaryButton>
  );

  const activeSessions = useMemo(() => user?.activeSessions || [], [user]);

  useClientLayoutEffect(() => {
    if (!snackbarText) return;

    showSnackbar({
      id: SNACKBAR_ID,
      Icon: CheckMark,
      text: snackbarText,
      closeAfter: 3000,
    });

    return () => {
      setSnackbarText(null);
    };
  }, [snackbarText]);

  const SelectedModal =
    selectedModal === EDIT_INFO
      ? UserSettings
      : selectedModal === EDIT_EMAIL
        ? EditEmail
        : selectedModal === DELETE_USER
          ? DeleteUser
          : null;

  return (
    <PageContainer>
      {user && (
        <div className="user-info">
          <div className="top-info">
            <UserInfo
              user={user}
              selectedLanguage={languageValue}
              RevealHideBtn={RevealHideBtn}
              RevealHideInfo={RevealHideInfo}
              refs={settingsRefs}
              handleSelectedModal={handleSelectedModal}
              blurEnabled={blurEnabled}
              setBlurEnabled={setBlurEnabled}
            />
          </div>
          <div className="bottom-info">
            <MetaDataWrapper>
              <RevealHideInfo
                label={t("common:email", "Email")}
                value={user?.email}
                blur={blurEnabled ? "blur" : ""}
              />
              <div className="buttons">
                <EventedRender>
                  <settingsRefs.Edit
                    editClick={handleSelectedModal}
                    modal={EDIT_EMAIL}
                  />
                </EventedRender>
              </div>
            </MetaDataWrapper>
          </div>
        </div>
      )}
      <Panel>
        <h5 className="type-article-headline">
          {t("common:general", "General")}
        </h5>
        <SettingsList>
          {applicationSettings
            .filter(({ hidden }) => !hidden)
            .map(({ name, label, description, input }) => (
              <SettingsListItem key={name}>
                <SettingsContent>
                  <SettingsLabel className="type-form--button">
                    {label}
                  </SettingsLabel>
                  {description && (
                    <SettingsDescription className="type-caption">
                      {description}
                    </SettingsDescription>
                  )}
                </SettingsContent>
                {input}
              </SettingsListItem>
            ))}
          {settingsListItems.map((Component, i) => (
            <Component key={i} />
          ))}
        </SettingsList>

        {IS_APP ? (
          <PanelWell>
            <BlitzVersion>
              <h6
                className="type-h6 flex gap-sp-2 align-center"
                onClick={() =>
                  globals.navigator.clipboard.writeText(mainRefs.PKG_VERSION)
                }
              >
                <LogoBlitz
                  width="16px"
                  height="16px"
                  className="inline-block"
                />
                {`${t("common:blitz", "Blitz")} ${mainRefs.PKG_VERSION}`}
              </h6>

              {/*<SettingsDescription className="type-caption">
                {t(
                  "common:settings.checkVersion",
                  "You're on the latest version. We automatically check for updates but you can check now anyway."
                )}
                </SettingsDescription>*/}
            </BlitzVersion>
            <ButtonGroup>
              {/*<PrimaryButton
                className="type-form--button"
                size="md"
                onClick={checkForUpdates}
              >
                {t("common:settings.updates", "Check for Updates")}
                </PrimaryButton>*/}
              <SecondaryButton
                className="type-form--button"
                iconLeft={<ReplayIcon width="18px" height="18px" />}
                onClick={restartApp}
              >
                {t("common:settings.restart", "Restart the app")}
              </SecondaryButton>
              <Button className="type-form--button" onClick={resetSessionData}>
                {t("common:settings.clearCache", "Reset All Data")}
              </Button>
            </ButtonGroup>
          </PanelWell>
        ) : null}
      </Panel>
      {user && (
        <Panel>
          <LoginSessions
            activeSessions={activeSessions}
            RevealHideBtn={RevealHideBtn}
            refs={settingsRefs}
          />
        </Panel>
      )}
      <Panel>
        <h5 className="type-article-headline">
          {t("common:language", "Language")}
        </h5>
        <LanguageSelect>
          {LOCALES.map(([code, name]) => (
            <label key={code} className="type-body2">
              <input
                type="radio"
                value={code}
                checked={code === languageValue}
                onChange={onChangeSelectedLanguage}
              />
              {name}
            </label>
          ))}
        </LanguageSelect>
      </Panel>
      <FullscreenOverlay
        isOpen={!!SelectedModal}
        onModalClose={() => setSelectedModal(null)}
      >
        {SelectedModal && (
          <SelectedModal
            user={user}
            email={user?.email || ""}
            close={handleSelectedModal}
            setSnackbarText={setSnackbarText}
          />
        )}
      </FullscreenOverlay>
    </PageContainer>
  );
}

export default GeneralSettings;
