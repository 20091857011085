import React from "react";
import { css } from "goober";

import { useHubRoute } from "@/app/util.mjs";
import { FavoriteGamesFilterBar } from "@/shared/GameFilterBar.jsx";
import PageHeader from "@/shared/PageHeader";
import { classNames } from "@/util/class-names.mjs";

const cssSticky = () => css`
  & {
    position: sticky;
    padding-block: var(--sp-2);
    z-index: 2;
  }
`;

const cssTitle = () => css`
  & {
    line-height: 1.2;
  }
`;

const HubHeader = ({ className = "", ...restProps }) => {
  const { title = "", Header = null } = useHubRoute() ?? {}; // Return type is HubRouteMeta | null

  return (
    <PageHeader
      title={title}
      {...classNames(className, cssTitle())}
      {...restProps}
    >
      <FavoriteGamesFilterBar className={cssSticky()} data-sticky-z="1" />
      {Header && <Header />}
    </PageHeader>
  );
};

export default HubHeader;
