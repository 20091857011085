import React, { useEffect, useRef } from "react";

import router from "@/__main__/router.mjs";
import HubHeader from "@/app/HubHeader.jsx";
import {
  cssHubTransition,
  HubContainer,
  HubContent,
} from "@/app/HubHeader.style.jsx";
import { useFavoriteGamesOrder } from "@/shared/GameFilterBar.jsx";
import { useInertTransitionParent } from "@/shared/Transition.jsx";
import { useGameSymbol } from "@/util/game-route.mjs";
import { useRouteComponent } from "@/util/router-hooks.mjs";

const HubLayout = ({ children, ...props }) => {
  // transition logic
  // move distance by tab position
  const gameSymbol = useGameSymbol();
  const gamesOrder = useFavoriteGamesOrder();
  const transitionParentRef = useInertTransitionParent({
    anim: { base: cssHubTransition(), enterFrom: "from" },
  });

  const tabDistance = useRef({ distance: 0, lastGame: null });

  useEffect(() => {
    tabDistance.current.distance =
      (gamesOrder[gameSymbol] ?? -1) -
      (gamesOrder[tabDistance.current.lastGame] ?? -1);

    tabDistance.current.lastGame = gameSymbol;

    transitionParentRef.current.style.setProperty(
      "--transition-distance",
      `calc(var(--sp-8) * ${tabDistance.current.distance})`,
    );
  }, [gameSymbol, gamesOrder, transitionParentRef]);

  // going to a different hub route should be a non-distance animation
  const routeComponent = useRouteComponent();

  useEffect(() => {
    transitionParentRef.current.style.setProperty(
      "--transition-distance",
      `calc(${router.isBackwards ? 1 : -1} * var(--sp-8))`,
    );
  }, [routeComponent, transitionParentRef]);

  return (
    <HubContainer {...props}>
      <HubHeader />
      <HubContent ref={transitionParentRef} className="relative">
        {children}
      </HubContent>
    </HubContainer>
  );
};

export default HubLayout;
