import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { updateActiveSessions } from "@/hub-settings/actions.mjs";
import { SecondaryButton } from "@/hub-settings/GeneralSettings.style.jsx";
import { Sessions } from "@/hub-settings/LoginSessions.style.jsx";
import { TimeRelative } from "@/shared/Time.jsx";

const LoginSessions = ({ activeSessions, RevealHideBtn, refs }) => {
  const { t } = useTranslation();
  const HEADERS = [
    t("common:settings.device", "Device"),
    t("common:settings.ipaddress", "IP Address"),
    t("common:settings.time", "Time"),
    t("common:settings.session", "Session"),
  ];

  const [blurEnabled, setBlurEnabled] = useState(true);

  const handleRemoveAllOtherSessions = useCallback(async () => {
    let activeSession;
    const ids = activeSessions?.reduce((acc, session) => {
      if (!session.isCurrentSession) {
        acc.push(session.sessionTrackerId);
      } else {
        activeSession = session.sessionTrackerId;
      }
      return acc;
    }, []);

    const result = await refs.removeSession(ids);
    if (result) {
      updateActiveSessions(activeSession, true);
    }
  }, [activeSessions, refs]);

  const handleRemoveSession = useCallback(
    async (row, item) => {
      const session = activeSessions[row];
      if (session.isCurrentSession || item !== 3) {
        return;
      }
      const result = await refs.removeSession([session.sessionTrackerId || ""]);
      if (result) {
        updateActiveSessions(session.sessionTrackerId, false);
      }
    },
    [activeSessions, refs],
  );

  const signOutTranslation = t("common:settings.signOut", "Sign Out");
  const sessions = useMemo(() => {
    return activeSessions?.reduce((acc, cur) => {
      const os = cur.operatingSystem.split(" ")[0] || "";
      const client = cur.browserClient.split(" ")[0] || "";
      const time = <TimeRelative date={new Date(cur.sessionCreatedAt)} />;
      const active = cur.isCurrentSession
        ? t("common:current", "Current")
        : signOutTranslation;

      acc.push([[client, os], cur.ip, time, active]);
      return acc;
    }, []);
  }, [activeSessions, t, signOutTranslation]);

  return (
    <Sessions>
      <div className="title">
        <h5 className="type-article-headline">
          {t("common:settings.loginSessions", "Login Sessions")}
        </h5>
        <div className="buttons">
          <div>
            <RevealHideBtn
              blurEnabled={blurEnabled}
              blurFn={() => setBlurEnabled((blur) => !blur)}
            />
          </div>

          <SecondaryButton
            className="form-button"
            onClick={() => handleRemoveAllOtherSessions()}
            disabled={activeSessions?.length <= 1}
          >
            {t("common:settings.signoutall", "Sign Out All Other Sessions")}
          </SecondaryButton>
        </div>
      </div>
      <div className="grid">
        <div className="headers">
          {HEADERS.map((header, idx) => {
            const lastItem = idx === HEADERS.length - 1 ? "session-item" : "";
            return (
              <div className={`type-caption ${lastItem}`} key={idx}>
                {header}
              </div>
            );
          })}
        </div>
        <div className="session-container">
          {sessions.map((session, rowIdx) => {
            return (
              <div className="session-row" key={rowIdx}>
                {session.map((item, itemIdx) => {
                  const lastItem =
                    itemIdx === session.length - 1
                      ? `session-item type-form--button`
                      : "";
                  const signOut = item !== signOutTranslation ? "" : "sign-out";
                  const defaultText = !(
                    itemIdx === 0 || itemIdx === session.length - 1
                  )
                    ? "type-caption"
                    : "";
                  return (
                    <div
                      className={`${defaultText} ${lastItem} ${signOut} ${
                        blurEnabled ? "blur" : ""
                      }`}
                      key={itemIdx}
                      onClick={() => handleRemoveSession(rowIdx, itemIdx)}
                    >
                      {Array.isArray(item) ? (
                        <div className="device">
                          <div className="type-subtitle2">{item[0]}</div>
                          <div className="type-body2 ">{item[1]}</div>
                        </div>
                      ) : (
                        item
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
          <div></div>
        </div>
      </div>
    </Sessions>
  );
};

export default LoginSessions;
